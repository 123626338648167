import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/AuthBlockingModal.css';
import { AuthModalProps } from '@client/store/types/cobranded-components/auth-modal';
import PillButton from '@client/components/generic/PillButton';
import {
  getCobrandId,
  getCobrandDisplayName,
  getBackToSiteLinkAndText,
} from '@client/store/selectors/cobranding.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

export const AuthBlockingModal: FC<AuthModalProps> = ({
  handleClose,
  isOpen,
}) => {
  const cobrandId = useSelector(getCobrandId);
  const cobrandTitle = useSelector(getCobrandDisplayName);
  const { homeSiteUrl } = useSelector(getBackToSiteLinkAndText);

  const handleRedirectClick = () => {
    if (homeSiteUrl) {
      window.open(homeSiteUrl);
    }
  };

  return (
    <SmallModal
      className={theme.AuthBlockingModal}
      dataHcName={`auth-modal-${cobrandId}`}
      isActive={isOpen}
      handleClose={() => handleClose()}
      theme={theme}
      modalAriaLabel="Sign-in with partner account"
    >
      <div className={theme.AuthBlockingModalContent} aria-live="assertive">
        <div
          className={theme.AuthBlockingModalTitle}
          role="heading"
          aria-level={2}
        >
          Please return to {cobrandTitle}
        </div>
        <div className={theme.AuthBlockingModalBody}>
          Account creation on ComeHome is currently unsupported. Please return
          to {cobrandTitle} to access ComeHome.
        </div>
        <div className={theme.AuthBlockingModalFooter}>
          {!!homeSiteUrl && (
            <PillButton
              ariaLabel="sign-in"
              theme={theme}
              onClick={handleRedirectClick}
              onKeyDown={onEnterOrSpaceKey(handleRedirectClick)}
            >
              Return to {cobrandTitle}
            </PillButton>
          )}
        </div>
      </div>
    </SmallModal>
  );
};

export default AuthBlockingModal;
