import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';
import defaultTheme from '@client/css-modules/FormSubmit.css';
import PillButton from '@client/components/generic/PillButton';

type FormSubmitProps = {
  disabled: boolean;
  onSubmit: (e: React.MouseEvent) => void;
  label: string;
  type?: 'button' | 'submit' | 'reset';
  theme: Theme;
  dataHcName?: string;
  style?: { [styleName: string]: string };
};

const FormSubmit = (props: FormSubmitProps) => {
  const { disabled, theme, onSubmit, label, dataHcName, ...rest } = props;
  return (
    <div className={theme.FormSubmit} data-hc-name={dataHcName}>
      <PillButton
        theme={theme}
        ariaLabel={label}
        onClick={onSubmit}
        disabled={disabled}
        {...rest}
      >
        {label}
      </PillButton>
    </div>
  );
};

FormSubmit.defaultProps = {
  disabled: true,
  label: null,
};

export default themr('FormSubmitThemed', defaultTheme)(FormSubmit);
