import { routeChange } from '@src/redux-saga-router-plus/actions';
import { getCurrentQuery } from '@src/redux-saga-router-plus/selectors';
import { connect } from 'react-redux';

import SignUp, { SignUpUserFields } from '@client/components/SignUp';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { authModalHide } from '@client/store/actions/auth.actions';
import { userInviteAgreeToTerms } from '@client/store/actions/invite.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { STATUSES } from '@client/store/constants';
import {
  getAuthStatus,
  getPendingUser,
  getSignUpErrorMessage,
  getValidationErrors,
} from '@client/store/selectors/auth.selectors';
import {
  getInvitedUserInfo,
  getShowInviteModalForSeller,
} from '@client/store/selectors/invite.selectors';
import { getIsMobile } from '@client/store/selectors/match-media.selectors';

type OwnProps = {
  loaded?: boolean;
  handleLoginClick?: () => void;
  isLODirectClientInviteSignUp?: boolean;
  handleCreateUser: (userInfo: SignUpUserFields) => void;
};

export const mapStateToProps = (state, ownProps: OwnProps) => {
  const status = getAuthStatus(state);
  const signUpFromInviteForSeller = getShowInviteModalForSeller(state);
  const { loid } = getCurrentQuery(state);
  const invitedUserInfo = getInvitedUserInfo(state);

  return {
    isLoaded: !!(
      status === STATUSES.SUCCESS ||
      status === STATUSES.ERROR ||
      ownProps.loaded
    ),
    isSubmitting: status === STATUSES.UPDATING,
    errorMessage: getSignUpErrorMessage(state),
    validationErrors: getValidationErrors(state),
    pendingUser: getPendingUser(state),
    focusFirstFieldOnMount: getIsMobile(state),
    signUpFromInviteForSeller,
    invitedUserInfo,
    /* TODO once the API changes to redirecting user to /chd-client-invite-sign-up, remove the `!!loid`
     * condition below, as it's only needed to support LO Direct client users signing up via /sign-up.
     * (this component is implemented within both pages) */
    isEmailFieldReadOnly:
      (ownProps.isLODirectClientInviteSignUp || !!loid) &&
      !!invitedUserInfo?.email,
  };
};

export const mapDispatchToProps = (dispatch, ownProps: OwnProps) => ({
  handleFormFieldInteraction: (field) => {
    if (field === 'email') {
      dispatch(reportEvent('signup_email', PARENT_EVENTS.CLICK_SIGNUP));
    } else if (field === 'password') {
      dispatch(reportEvent('signup_password', PARENT_EVENTS.CLICK_SIGNUP));
    } else if (field === 'firstname') {
      dispatch(reportEvent('signup_firstname', PARENT_EVENTS.CLICK_SIGNUP));
    } else if (field === 'lastname') {
      dispatch(reportEvent('signup_lastname', PARENT_EVENTS.CLICK_SIGNUP));
    }
  },
  handleLoginClick:
    ownProps.handleLoginClick ||
    (() => {
      dispatch(routeChange({ view: View.LOGIN }));
    }),
  skipToResults: () => {
    dispatch(userInviteAgreeToTerms());
    dispatch(authModalHide());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
