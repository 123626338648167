import { isEqual } from 'lodash';
import React, { Component } from 'react';

import CobrandedComponent, {
  CobrandedComponentArgs,
} from '@client/components/CobrandedComponent';
import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import Checkbox from '@client/components/generic/Checkbox';
import EmailField from '@client/components/generic/EmailField';
import FormError from '@client/components/generic/FormError';
import FormSubmit from '@client/components/generic/FormSubmit';
import PasswordField from '@client/components/generic/PasswordField';
import PhoneInput from '@client/components/generic/PhoneInput';
import TextInput, {
  TextInputProps,
} from '@client/components/generic/TextInput';
import ValidatorInput, {
  ValidatorProps,
} from '@client/components/generic/ValidatorInput';
import RouterLink from '@client/components/RouterLink';
import SignUpOrLoginLink from '@client/components/SignUpOrLoginLink';
import theme from '@client/css-modules/SignUp.css';
import { View } from '@client/routes/constants';
import {
  getIsNotRequiredValidator,
  getIsRequiredValidator,
  phoneValidator,
  VALIDATION_ERROR,
} from '@client/services/validator';
import { InputEvent, ValidationErrors } from '@client/store/types/auth';
import { InvitedUserInfo } from '@client/store/types/invite';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { stripOutNonNumber } from '@client/utils/string.utils';
import { isValidPhoneNumber } from '@client/utils/validations.forms';

const ValidatedTextInput = ValidatorInput<string>(TextInput);
const TextField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedTextInput
    dataHcName={'text-control'}
    validate={[getIsRequiredValidator()]}
    {...props}
  />
);

const ValidatedPhoneInput = ValidatorInput<string>(PhoneInput);
const PhoneField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedPhoneInput
    dataHcName={'phone-control'}
    validate={[getIsNotRequiredValidator(phoneValidator, stripOutNonNumber)]}
    {...props}
  />
);

export type SignUpUserFields = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  password: string;
};

type Props = {
  isLoaded: boolean;
  errorMessage: string | null;
  pendingUser: {
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
  };
  isSubmitting: boolean;
  handleCreateUser: (userInfo: SignUpUserFields) => void;
  handleLoginClick: () => void;
  focusFirstFieldOnMount: boolean;
  handleFormFieldInteraction: (field: string) => void;
  validationErrors: ValidationErrors;
  signUpFromInviteForSeller: boolean;
  invitedUserInfo?: InvitedUserInfo;
  skipToResults: () => void;
  isEmailFieldReadOnly: boolean;
};

type State = {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  password: string;
  firstnameValidationError?: string | null;
  lastnameValidationError?: string | null;
  emailValidationError?: string | null;
  passwordValidationError?: string | null;
  hasAgreedToTerms: boolean;
};

class SignUp extends Component<Props, State> {
  static defaultProps = {
    title: null,
    errorMessage: null,
    isSubmitting: false,
    focusFirstFieldOnMount: true,
  };
  constructor(props: Props) {
    super(props);

    const {
      pendingUser,
      invitedUserInfo,
      validationErrors,
      signUpFromInviteForSeller,
    } = props;

    this.state = {
      firstname: pendingUser.firstname ?? invitedUserInfo?.firstName ?? '',
      lastname: pendingUser.lastname ?? invitedUserInfo?.lastName ?? '',
      email: pendingUser.email ?? invitedUserInfo?.email ?? '',
      phone: invitedUserInfo?.phone ?? '',
      password: '',
      emailValidationError: validationErrors.email,
      passwordValidationError: validationErrors.password,
      hasAgreedToTerms: signUpFromInviteForSeller,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { validationErrors } = this.props;
    if (!isEqual(prevProps.validationErrors, validationErrors)) {
      this.setState({
        emailValidationError: validationErrors.email,
        passwordValidationError: validationErrors.password,
      });
    }
  }

  getUser() {
    return {
      email: this.state.email,
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      phone: !!this.state.phone ? stripOutNonNumber(this.state.phone) : null,
      password: this.state.password,
    };
  }

  submitForm = (e: React.FormEvent): void => {
    e.preventDefault();
    this.props.handleCreateUser(this.getUser());
  };

  handleSignupChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ [e.target.name]: e.target.value } as any);
  };

  handleSignupValid = (reason: string, e: InputEvent) => {
    let stateObj = { [`${(e.target as HTMLInputElement).name}Status`]: true };
    // User changed the value, reset the validation error
    if (this.state[`${(e.target as HTMLInputElement).name}ValidationError`]) {
      stateObj = {
        ...stateObj,
        [`${(e.target as HTMLInputElement).name}ValidationError`]: null,
      } as any;
    }
    this.setState(stateObj as any);
  };

  handleSignupInvalid = (reason: string, e: InputEvent): void => {
    this.setState({
      [`${(e.target as HTMLInputElement).name}Status`]: false,
      [`${(e.target as HTMLInputElement).name}ValidationError`]: reason,
    } as any);
  };

  reportBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    this.props.handleFormFieldInteraction(e.target.name);
  };

  // Phone is optional field, so if there is nothing entered, we allow it to submit. However, if
  // anything is entered in that field then we need to check that it's a valid phone number.
  isPhoneSubmittable = (phone: string) =>
    phone.length === 0 || isValidPhoneNumber(phone);

  checkValidation = () => {
    const { email, firstname, lastname, phone, password, hasAgreedToTerms } =
      this.state;
    const phoneStripped = stripOutNonNumber(phone);
    return (
      !!email &&
      !!password &&
      !!firstname &&
      !!lastname &&
      this.isPhoneSubmittable(phoneStripped) &&
      hasAgreedToTerms
    );
  };

  handleOnTermsCheckboxClick = () => {
    const { hasAgreedToTerms } = this.state;
    this.setState({ hasAgreedToTerms: !hasAgreedToTerms });
  };

  render() {
    const {
      isLoaded,
      isSubmitting,
      errorMessage,
      signUpFromInviteForSeller,
      handleLoginClick,
      isEmailFieldReadOnly,
    } = this.props;
    const {
      firstnameValidationError,
      lastnameValidationError,
      emailValidationError,
      passwordValidationError,
      hasAgreedToTerms,
    } = this.state;

    return isLoaded ? (
      <CobrandedDataComponent>
        {({
          customizationData: { should_show_privacy_policy_links },
        }: CobrandedDataComponentArgs) => (
          <CobrandedComponent>
            {({
              styles: { linkColor },
              utils: { getFormattedCTAText },
            }: CobrandedComponentArgs) => (
              <div className={theme.SignupForm}>
                <SignUpOrLoginLink
                  data-hc-name={'log-in-row'}
                  theme={theme}
                  handleClick={handleLoginClick}
                  linkType="login"
                />
                <form onSubmit={this.submitForm}>
                  <TextField
                    value={this.state.firstname}
                    name={'firstname'}
                    ariaDescribedby="error-firstname"
                    ariaLabel="first name"
                    label={'First name*'}
                    theme={theme}
                    onChange={(e) => this.handleSignupChange(e)}
                    onValid={this.handleSignupValid}
                    onInvalid={this.handleSignupInvalid}
                    onBlur={this.reportBlur}
                    error={
                      firstnameValidationError
                        ? 'Please enter your first name'
                        : undefined
                    }
                  />
                  <TextField
                    value={this.state.lastname}
                    name={'lastname'}
                    label={'Last name*'}
                    ariaDescribedby="error-lastname"
                    ariaLabel="last name"
                    theme={theme}
                    onChange={(e) => this.handleSignupChange(e)}
                    onValid={this.handleSignupValid}
                    onInvalid={this.handleSignupInvalid}
                    onBlur={this.reportBlur}
                    error={
                      lastnameValidationError
                        ? 'Please enter your last name'
                        : undefined
                    }
                  />
                  <EmailField
                    theme={theme}
                    label="Email*"
                    name={'email'}
                    ariaDescribedby="error-email"
                    ariaLabel="email"
                    value={this.state.email}
                    required
                    onChange={(e) => this.handleSignupChange(e)}
                    onValid={this.handleSignupValid}
                    onInvalid={this.handleSignupInvalid}
                    onBlur={this.reportBlur}
                    error={
                      !!emailValidationError
                        ? emailValidationError === VALIDATION_ERROR.Required
                          ? 'Please enter your email'
                          : emailValidationError
                        : undefined
                    }
                    readOnly={isEmailFieldReadOnly}
                    style={
                      isEmailFieldReadOnly
                        ? { backgroundColor: '#F0F0F0' }
                        : undefined
                    }
                  />
                  <PhoneField
                    value={this.state.phone}
                    name="phone"
                    label="Phone"
                    ariaDescribedby="error-phone"
                    ariaLabel="phone"
                    theme={theme}
                    onChange={(e) => this.handleSignupChange(e)}
                    onValid={this.handleSignupValid}
                    onInvalid={this.handleSignupInvalid}
                    onBlur={this.reportBlur}
                  />
                  <PasswordField
                    theme={theme}
                    label="Password*"
                    ariaDescribedby="error-password"
                    name={'password'}
                    onChange={(e) => this.handleSignupChange(e)}
                    onValid={this.handleSignupValid}
                    onInvalid={this.handleSignupInvalid}
                    onBlur={this.reportBlur}
                    error={
                      !!passwordValidationError
                        ? passwordValidationError === VALIDATION_ERROR.Required
                          ? 'Please enter your password'
                          : passwordValidationError
                        : undefined
                    }
                  />
                  <div className={theme.RequiredFieldsMessage}>Required*</div>
                  <div
                    className={theme.TermsCheckboxContainer}
                    data-hc-name={'confirm-row'}
                  >
                    {!signUpFromInviteForSeller && (
                      <Checkbox
                        theme={theme}
                        name={'terms-checkbox'}
                        onClick={this.handleOnTermsCheckboxClick}
                        onKeyDown={onEnterOrSpaceKey(
                          this.handleOnTermsCheckboxClick
                        )}
                        label="Terms of Service Agreement"
                        offScreenLabel
                        isChecked={hasAgreedToTerms}
                      />
                    )}
                    <div className={theme.TermsLink}>
                      <span>
                        By{' '}
                        {signUpFromInviteForSeller
                          ? 'continuing'
                          : 'registering'}
                        , I agree to ComeHome&nbsp;
                      </span>
                      <RouterLink
                        target="_blank"
                        style={{ color: linkColor }}
                        data-ignore-intercept
                        view={View.TERMS_OF_USE}
                        ariaLabel="terms of use"
                      >
                        Terms of Use
                      </RouterLink>
                      {should_show_privacy_policy_links && (
                        <>
                          <span>&nbsp;and&nbsp;</span>
                          <RouterLink
                            target="_blank"
                            style={{ color: linkColor }}
                            data-ignore-intercept
                            view={View.PRIVACY_POLICY}
                            ariaLabel="Privacy Policy"
                          >
                            Privacy Policy
                          </RouterLink>
                        </>
                      )}
                    </div>
                  </div>
                  {!hasAgreedToTerms && (
                    <div className={theme.TermsRequiredMessage}>Required</div>
                  )}
                  <FormSubmit
                    dataHcName={'signup-button'}
                    theme={theme}
                    type="submit"
                    disabled={isSubmitting || !this.checkValidation()}
                    onSubmit={this.submitForm}
                    label={
                      isSubmitting
                        ? 'Submitting'
                        : getFormattedCTAText('Sign Up')
                    }
                  />
                  {this.props.signUpFromInviteForSeller && (
                    <div className={theme.SkipForNow}>
                      <button
                        type="button"
                        onClick={() => {
                          this.props.skipToResults();
                        }}
                        aria-label="Skip for now"
                      >
                        Skip for now
                      </button>
                    </div>
                  )}
                  {errorMessage && (
                    <FormError
                      data-hc-name={'form-error-message'}
                      theme={theme}
                      value={errorMessage}
                    />
                  )}
                </form>
              </div>
            )}
          </CobrandedComponent>
        )}
      </CobrandedDataComponent>
    ) : null;
  }
}

export default SignUp;
