import { routeChange } from '@src/redux-saga-router-plus/actions';
import { connect } from 'react-redux';

import Login from '@client/components/Login';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { login } from '@client/store/actions/auth.actions';
import { STATUSES } from '@client/store/constants';
import {
  getAuthStatus,
  getLoginErrorMessage,
} from '@client/store/selectors/auth.selectors';
import { getActiveModalKey } from '@client/store/selectors/modals.selectors';
import { AfterAuthAction } from '@client/store/types/auth';

type OwnProps = {
  handleSignupClick?: () => void;
  handleForgotPasswordClick?: () => void;
  routeAfterLoginSuccess?: View;
  afterAuthAction?: AfterAuthAction;
};

const mapStateToProps = (state) => {
  const status = getAuthStatus(state);

  return {
    errorMessage: getLoginErrorMessage(state),
    isLoading: status === STATUSES.LOADING,
    routeAfterLoginSuccess:
      getActiveModalKey(state) === 'homeowner-search-modal'
        ? View.HOMEOWNER
        : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps: OwnProps) => {
  return {
    handleLogin: (credentials) => {
      dispatch(
        login(
          credentials,
          ownProps.routeAfterLoginSuccess,
          ownProps.afterAuthAction
        )
      );
    },
    handleFormFieldInteraction: (field) => {
      if (field === 'email') {
        dispatch(reportEvent('signin_email'));
      } else if (field === 'password') {
        dispatch(reportEvent('signin_password'));
      }
    },
    handleSignupClick:
      ownProps.handleSignupClick ||
      (() => {
        dispatch(routeChange({ view: View.SIGN_UP }));
      }),
    handleForgotPasswordClick:
      ownProps.handleForgotPasswordClick ||
      (() => {
        dispatch(routeChange({ view: View.FORGOT_PASSWORD }));
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
