import React from 'react';
import TextInput, {
  TextInputProps,
} from '@client/components/generic/TextInput';
import { formatPhoneInputForDisplay } from '@client/utils/string.utils';

const PhoneInput: React.FunctionComponent<TextInputProps> = ({
  label,
  value,
  formatter,
  ...props
}) => {
  return (
    <TextInput
      type="tel"
      label={label}
      maxLength={18}
      value={!formatter ? formatPhoneInputForDisplay(value) : formatter(value)}
      {...props}
    />
  );
};

export default PhoneInput;
