import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import removeWarningIconUrl from '@client/assets/images/cobrand/chase/chase-remove-warning.png';
import PillButton from '@client/components/generic/PillButton';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/AuthModalChase.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useSSORedirect } from '@client/hooks/sso-redirect.hooks';
import { reportClickCobrandSignIn } from '@client/store/actions/analytics.actions';
import { CHASE_WEB_REDIRECT } from '@client/store/actions/cobranding.actions';
import { getAuthenticatonRedirectUrl } from '@client/store/sagas/chase-integration.saga';
import { getAuthModalAfterAuthAction } from '@client/store/selectors/auth.selectors';
import { getCustomizationData } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { AuthModalProps } from '@client/store/types/cobranded-components/auth-modal';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

export const AuthModalChase: FC<
  Pick<AuthModalProps, 'isOpen' | 'handleClose'>
> = ({ handleClose, isOpen }) => {
  const ssoRedirect = useSSORedirect();
  const isChaseNonSpEnabled = useSelector(
    getIsFeatureEnabled('chase_non_sp_enabled')
  );
  const { cobrand_cta_redirect_hostname } = useSelector(getCustomizationData);
  const dispatch = useDispatch();
  const afterAuthAction = useSelector(getAuthModalAfterAuthAction);
  const handleSignInClick = () => {
    dispatch(reportClickCobrandSignIn());
    /* Note: we're overloading the standard afterAuthAction paradigm within the Chase cobrand for a flow unique to Chase:
     * For CTAs pointing to Chase URLs (i.e. 'See Loan Options'), Chase wants this auth modal to redirect users directly
     * to those URLs rather than to Chase's generic authentication URL.
     * This is facilitated by setting the afterAuthAction state in chase-integration.saga.ts */
    if (afterAuthAction && afterAuthAction.type === CHASE_WEB_REDIRECT) {
      if (!afterAuthAction.payload.url) {
        throw new Error('Missing URL in CHASE_WEB_REDIRECT afterAuthAction');
      }
      window.location.href = afterAuthAction.payload.url;
      /* For all other user actions that require authentication (i.e. saving a search), redirect to Chase's generic
       * authentication URL */
    } else if (isChaseNonSpEnabled) {
      ssoRedirect();
    } else {
      window.location.href = getAuthenticatonRedirectUrl({
        hostname: cobrand_cta_redirect_hostname as string | null,
      });
    }
  };
  const { pillButtonPrimaryBackgroundColor } = useCobrandStyles();

  return (
    <SmallModal
      className={theme.AuthModalChase}
      dataHcName={'auth-modal-chase'}
      isActive={isOpen}
      handleClose={() => handleClose()}
      theme={theme}
    >
      <div className={theme.AuthModalChaseContent} aria-live="assertive">
        <div
          className={theme.AuthModalChaseTitle}
          role="heading"
          aria-level={2}
        >
          <div
            className={theme.RemoveWarningIconContainer}
            style={{ backgroundImage: `url(${removeWarningIconUrl})` }}
          />
          Sign in
        </div>
        <div className={theme.AuthModalChaseBody}>
          To get the full ComeHome experience,
          <br />
          please sign in to your Chase account.
        </div>
        <div className={theme.AuthModalChaseFooter}>
          <PillButton
            ariaLabel="sign-in"
            theme={theme}
            onClick={handleSignInClick}
            onKeyDown={onEnterOrSpaceKey(handleSignInClick)}
          >
            Sign in
          </PillButton>
          <PillButton
            ariaLabel="cancel"
            theme={theme}
            style={{
              color: pillButtonPrimaryBackgroundColor,
              borderColor: pillButtonPrimaryBackgroundColor,
            }}
            onClick={() => handleClose()}
            onKeyDown={onEnterOrSpaceKey(() => handleClose())}
            deemphasized
          >
            Cancel
          </PillButton>
        </div>
      </div>
    </SmallModal>
  );
};

export default AuthModalChase;
