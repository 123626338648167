import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import SmallModal from '@client/components/generic/SmallModal';
import theme from '@client/css-modules/AuthModalNativeAppIntegration.css';
import PillButton from '@client/components/generic/PillButton';
import { AuthModalProps } from '@client/store/types/cobranded-components/auth-modal';
import { useNativeAppAuthentication } from '@client/hooks/native-app-integration.hooks';
import {
  getCobrandDisplayName,
  getCobrandId,
} from '@client/store/selectors/cobranding.selectors';

export const AuthModalNativeAppIntegration: FC<
  Pick<AuthModalProps, 'handleClose' | 'afterAuthAction' | 'isOpen'>
> = ({ handleClose, afterAuthAction, isOpen }) => {
  const cobrandId = useSelector(getCobrandId);
  const cobrandTitle = useSelector(getCobrandDisplayName);
  const authenticate = useNativeAppAuthentication();
  const handleAuthenticate = () => {
    authenticate(afterAuthAction);
  };

  return (
    <SmallModal
      className={theme.AuthModalNativeAppIntegration}
      dataHcName={`auth-modal-${cobrandId}`}
      isActive={isOpen}
      handleClose={() => handleClose()}
      theme={theme}
      modalAriaLabel="Sign-in with partner account"
    >
      <div
        className={theme.AuthModalNativeAppIntegrationContent}
        aria-live="assertive"
      >
        <div
          className={theme.AuthModalNativeAppIntegrationTitle}
          role="heading"
          aria-level={2}
        >
          Sign in with {cobrandTitle}
        </div>
        <div className={theme.AuthModalNativeAppIntegrationBody}>
          To get the full ComeHome experience,
          <br />
          please sign in to your {cobrandTitle} account.
        </div>
        <div className={theme.AuthModalNativeAppIntegrationFooter}>
          <PillButton
            ariaLabel="sign-in"
            theme={theme}
            onClick={handleAuthenticate}
            onKeyDown={onEnterOrSpaceKey(handleAuthenticate)}
          >
            Sign in
          </PillButton>
        </div>
      </div>
    </SmallModal>
  );
};

export default AuthModalNativeAppIntegration;
