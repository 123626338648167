import { motion } from 'framer-motion';
import React, { Component } from 'react';

import AuthBlockingModal from '@client/components/AuthBlockingModal';
import AuthModalForCobrandSSO from '@client/components/AuthModalForCobrandSSO';
import AuthModalNativeAppIntegration from '@client/components/AuthModalNativeAppIntegration';
import CobrandedStyles from '@client/components/CobrandedStyles';
import { SignUpUserFields } from '@client/components/SignUp';
import LoginContainer from '@client/containers/login.container';
import SignUpContainer from '@client/containers/sign-up.container';
import SlideInModalContainer from '@client/containers/slide-in-modal.container';
import theme from '@client/css-modules/AuthModal.css';
import { View } from '@client/routes/constants';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { AuthModalPage } from '@client/store/types/auth';
import { AuthModalProps } from '@client/store/types/cobranded-components/auth-modal';

const SIGNUP_STEP = AUTH_MODAL_PAGES.SIGN_UP;
const LOGIN_STEP = AUTH_MODAL_PAGES.LOGIN;

type State = {
  step: AuthModalPage;
};

class AuthModal extends Component<AuthModalProps, State> {
  state: State = {
    step: SIGNUP_STEP,
  };

  handleTransitionToLogin = (): void => {
    this.setState({ step: LOGIN_STEP });
  };

  handleTransitionToSignup = (): void => {
    this.setState({ step: SIGNUP_STEP });
  };

  componentDidUpdate(prevProps: AuthModalProps) {
    // Close modal on reroute
    if (prevProps.currentView !== this.props.currentView && this.props.isOpen) {
      this.props.handleClose();
    }
    /* When opening the modal, always use the starting step/page defined in props */
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ step: this.props.startingPage });
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      this.props.handleClose();
    }
  }

  handleClose = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.stopPropagation();
    if (!this.props.disableClose) {
      this.props.handleClose(this.state.step);
    }
  };

  localHandleCreateUser = (userInfo: SignUpUserFields): void => {
    const { handleCreateUser } = this.props;
    handleCreateUser(userInfo, this.props.afterAuthAction || null);
  };

  render() {
    const {
      isOpen,
      authScheme,
      afterAuthAction,
      subHeadingText,
      headingText,
      isUsingSPSamlLogin,
      isInsideNativeApp,
      disableClose,
    } = this.props;
    const { step } = this.state;

    const HEADER_TEXT_FOR_STEP = {
      [SIGNUP_STEP]: {
        title: headingText[SIGNUP_STEP],
        subtitle: subHeadingText[SIGNUP_STEP],
      },
      [LOGIN_STEP]: {
        title: headingText[LOGIN_STEP],
        subtitle: subHeadingText[LOGIN_STEP],
      },
    };
    const FORM_FADE_ANIM_CONFIG = {
      initial: { opacity: 0 },
      animate: { opacity: 1, transition: { duration: 0.3, delay: 0.1 } },
    };
    const isConciergeTeamViewAfterAuth =
      (afterAuthAction?.payload as { view: string })?.view ===
      View.CONCIERGE_TEAM;

    /* Native app oauth */
    if (authScheme === 'oauth' && isInsideNativeApp) {
      return (
        <AuthModalNativeAppIntegration
          handleClose={this.props.handleClose}
          afterAuthAction={this.props.afterAuthAction}
          isOpen={isOpen}
        />
      );
      /* SP SAML or OAuth */
    } else if (
      (authScheme === 'saml' && isUsingSPSamlLogin) ||
      authScheme === 'oauth'
    ) {
      return (
        <AuthModalForCobrandSSO
          handleClose={this.props.handleClose}
          isOpen={isOpen}
          disableClose={disableClose}
        />
      );
      /* IdP SAML */
    } else if (authScheme === 'saml' && !isUsingSPSamlLogin) {
      return <AuthBlockingModal {...this.props} />;
      /* ComeHome authentication */
    } else {
      return (
        <CobrandedStyles>
          {({ authModalHeadingBackground }) => (
            <SlideInModalContainer
              className={theme.AuthModal}
              theme={theme}
              dataHcName={'auth-modal'}
              modalAriaLabel={`${HEADER_TEXT_FOR_STEP[step].title}, ${HEADER_TEXT_FOR_STEP[step].subtitle}`}
              isActive={isOpen}
              handleClose={this.handleClose}
              hideCloseIcon={disableClose}
            >
              <div>
                <div
                  data-hc-name={'modal-header'}
                  className={theme.Header}
                  style={{
                    backgroundColor: authModalHeadingBackground,
                    display: 'flex',
                  }}
                >
                  <div className={theme.Title}>
                    {isConciergeTeamViewAfterAuth
                      ? 'Find an agent'
                      : HEADER_TEXT_FOR_STEP[step].title}
                  </div>
                  <div className={theme.Subtitle}>
                    {isConciergeTeamViewAfterAuth
                      ? 'To contact an agent, please sign up or log in'
                      : HEADER_TEXT_FOR_STEP[step].subtitle}
                  </div>
                </div>
                <div className={theme.Body} data-hc-name={'modal-body'}>
                  {step === SIGNUP_STEP && (
                    <motion.div
                      key="sign-up"
                      className={theme.FormContainer}
                      {...FORM_FADE_ANIM_CONFIG}
                    >
                      <SignUpContainer
                        loaded
                        handleLoginClick={this.handleTransitionToLogin}
                        handleCreateUser={this.localHandleCreateUser}
                      />
                    </motion.div>
                  )}
                  {step === LOGIN_STEP && (
                    <motion.div
                      key="login"
                      className={theme.FormContainer}
                      {...FORM_FADE_ANIM_CONFIG}
                    >
                      <LoginContainer
                        afterAuthAction={afterAuthAction}
                        handleSignupClick={this.handleTransitionToSignup}
                      />
                    </motion.div>
                  )}
                </div>
              </div>
            </SlideInModalContainer>
          )}
        </CobrandedStyles>
      );
    }
  }
}

export default AuthModal;
