import TextInput, {
  TextInputProps,
} from '@client/components/generic/TextInput';
import {
  passwordValidator,
  getIsRequiredValidator,
} from '@client/services/validator';
import ValidatorInput, {
  ValidatorProps,
} from '@client/components/generic/ValidatorInput';

const PasswordInput = (props: TextInputProps) => (
  <TextInput type="password" {...props} />
);

const ValidatedPasswordInput = ValidatorInput<string>(PasswordInput);
/**
 * Password field with password validator
 * used in login, sign up, reset password forms
 */
const PasswordField = (
  props: TextInputProps & Omit<ValidatorProps<string>, 'validate'>
) => (
  <ValidatedPasswordInput
    dataHcName={'password-control'}
    validate={[getIsRequiredValidator(passwordValidator)]}
    error={props.error}
    {...props}
  />
);

export default PasswordField;
