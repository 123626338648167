import React from 'react';
import { themr, Theme } from '@friendsofreactjs/react-css-themr';

import SignUpOrLoginLinkTheme from '@client/css-modules/SignUpOrLoginLink.css';
import FlatButton from '@client/components/generic/FlatButton';
import ChevronIconCobranded from '@client/components/ChevronIcon/ChevronIconCobranded';

const DEFAULT_LINK_QUESTION = {
  login: 'Already have an account?',
  signup: "Don't have an account?",
};

const LINK_TEXT = {
  login: 'Log in',
  signup: 'Sign up',
};

type SignUpOrLoginLinkProps = {
  handleClick: () => void;
  theme: Theme;
  linkType: 'login' | 'signup';
  linkQuestionText?: string;
  labelStyle?: React.CSSProperties;
};

const SignUpOrLoginLink: React.FunctionComponent<SignUpOrLoginLinkProps> = ({
  theme,
  handleClick,
  linkType,
  linkQuestionText,
  labelStyle,
  ...props
}) => {
  return (
    <div className={theme.SignUpOrLoginLink} {...props}>
      {linkQuestionText ? (
        <span className={theme.LinkQuestionText}>{linkQuestionText}&nbsp;</span>
      ) : (
        <span>{DEFAULT_LINK_QUESTION[linkType]}&nbsp;</span>
      )}
      <FlatButton
        data-event-name={`click_${linkType}_cta`}
        theme={theme}
        onClick={handleClick}
        label={LINK_TEXT[linkType]}
        aria-label={LINK_TEXT[linkType]}
        labelStyle={labelStyle}
        icon={
          <ChevronIconCobranded
            className={theme.ChevronIcon}
            aria-hidden="true"
          />
        }
      />
    </div>
  );
};

const ThemedSignUpOrLoginLink = themr(
  'ThemedSignUpOrLoginLink',
  SignUpOrLoginLinkTheme
)(SignUpOrLoginLink);
export default ThemedSignUpOrLoginLink;
