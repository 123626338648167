import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import AuthModalCobranded from '@client/components/AuthModal/AuthModalCobranded';
import { SignUpUserFields } from '@client/components/SignUp';
import { View } from '@client/routes/constants';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { authModalHide, createUser } from '@client/store/actions/auth.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import {
  getAuthModalAfterAuthAction,
  getAuthModalHeadingText,
  getAuthModalStartingPage,
  getAuthModalState,
  getAuthModalSubHeadingText,
} from '@client/store/selectors/auth.selectors';
import {
  getCobrandAuthScheme,
  getIsInsideNativeApp,
} from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getShowInviteModalForSeller } from '@client/store/selectors/invite.selectors';
import { AfterAuthAction, AuthModalPage } from '@client/store/types/auth';
import { ReduxState } from '@client/store/types/redux-state';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

type OwnProps = {
  disableClose?: boolean;
  isOpen?: boolean;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const authModalState = getAuthModalState(state);
  const authScheme = getCobrandAuthScheme(state);

  return {
    authScheme,
    isOpen: ownProps.isOpen || authModalState.isOpen,
    afterAuthAction: getAuthModalAfterAuthAction(state),
    startingPage: getAuthModalStartingPage(state),
    subHeadingText: getAuthModalSubHeadingText(state),
    headingText: getAuthModalHeadingText(state),
    currentView: getCurrentView(state) as View,
    isUsingSPSamlLogin: getIsFeatureEnabled('use_sp_saml_login')(state),
    isInsideNativeApp: getIsInsideNativeApp(state),
    // Allow overriding disableClose, but fallback to disabling close only for "standard" auth scheme
    // cobrands (i.e. "www") when showing a specific invite sign-up modal for homeowners
    disableClose:
      ownProps.disableClose ||
      (getShowInviteModalForSeller(state) && authScheme === 'standard'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleClose: (page?: AuthModalPage) => {
    if (page && Object.values(AUTH_MODAL_PAGES).includes(page)) {
      switch (page) {
        case AUTH_MODAL_PAGES.LOGIN:
          dispatch(
            reportEvent('click_login_back_button', PARENT_EVENTS.CLICK_SIGNUP)
          );
          break;
        case AUTH_MODAL_PAGES.SIGN_UP:
          dispatch(
            reportEvent('click_signup_back_button', PARENT_EVENTS.CLICK_SIGNUP)
          );
          break;
        default:
          break;
      }
    }
    dispatch(authModalHide());
  },
  handleCreateUser: (
    userInfo: SignUpUserFields,
    afterAuthAction: AfterAuthAction
  ) => {
    dispatch(createUser(userInfo, afterAuthAction));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthModalCobranded);
